import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View account',
  endpoint: endpoints.GET_ACCOUNT,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Name', key: 'name', type: ComponentList.TEXT },
    { label: 'Abbreviation', key: 'abbreviation', type: ComponentList.TEXT },
    { label: 'Background check required?', key: 'background_check', type: ComponentList.YES_OR_NO },
    {
      label: 'Alcohol Training Instructions',
      key: 'alcohol_instructions_url',
      type: ComponentList.ALCOHOL_INSTRUCTIONS
    }
  ]
};
