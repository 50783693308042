import React, { useState } from 'react';
import useWindowSize from '@revolt-digital/use-window-size';
import get from 'lodash.get';
import classnames from 'classnames';
import { ArrowDown2, ArrowLeft2, ArrowRight2, Filter, FilterRemove, More, Refresh } from 'iconsax-react';
import { Menu as RMenu, MenuItem, MenuRadioGroup } from '@szhsin/react-menu';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from 'constants/breakpoints';
import {
  Account,
  AlcoholCertificationStatus,
  BackgroundCheckStatus,
  Button,
  Checkbox,
  DateFormat,
  DateTimeFormat,
  DropdownButton,
  SearchInToolbar,
  TextCopy,
  Tooltip,
  YesOrNo,
  GoodOrBad,
  Venue,
  Venues,
  AlcoholCertificationStatusTag,
  EditableCell,
  PhoneNumber,
  Email,
  EditableMoneyCell,
  EditableHourCell,
  PaymentMethod,
  File,
  Link,
  Positions,
  YoutubeTags,
  DocsTags,
  QuizTags,
  UniformApprovalStatus,
  DownloadImage,
  NotificationTrigger,
  ViewVideo,
  DownloadResume,
  ApplicationStatus,
  PrimaryText
} from 'components';
import { ComponentList, Condition, MenuButton, sortBy, useTableReturn } from 'types/table';
import PayrollFeedback from '../payrollFeedback';

// const ColumnsSwitcher = () => <Menu className="column-switcher" />;

const getConditions = (row: any, conditional: Condition[]) => {
  return conditional.reduce((acc, cur) => {
    const aux: any = acc;
    const value = cur.value(row);

    if (value) {
      aux[cur.property] = value;
    }

    return aux;
  }, {});
};

const getMenuConditions = (row: any, menuButtons: MenuButton[]) => {
  return menuButtons.map(item => ({
    ...item,
    ...(!item.conditional ? {} : getConditions(row, item.conditional)),
    conditional: undefined
  }));
};

const DynamicCell = ({
  columnId,
  type,
  row,
  value,
  componentProps,
  timezone,
  handleRefresh,
  setTableData,
  onSave
}: {
  columnId: string;
  type: ComponentList;
  row: any;
  value: any;
  componentProps: any;
  timezone: string;
  handleRefresh: () => void;
  setTableData: (data: any) => void;
  onSave: (data: any) => void;
}) => {
  return (
    <React.Fragment>
      {
        {
          [ComponentList.ACCOUNT]: <Account value={value} />,
          [ComponentList.ALCOHOL_CERTIFICATION_STATUS]: (
            <AlcoholCertificationStatus value={value} handleRefresh={handleRefresh} />
          ),
          [ComponentList.ALCOHOL_CERTIFICATION_STATUS_TAG]: <AlcoholCertificationStatusTag value={value} />,
          [ComponentList.UNIFORM_APPROVAL]: <UniformApprovalStatus value={value} handleRefresh={handleRefresh} />,
          [ComponentList.BACKGROUND_CHECK_STATUS]: <BackgroundCheckStatus value={value} />,
          [ComponentList.TEXT_COPY]: <TextCopy value={value} />,
          [ComponentList.TEXT]: <span>{value}</span>,
          [ComponentList.MONEY]: <span>${value}</span>,
          [ComponentList.LIST]: <span>{!Array.isArray(value) ? '-' : value.join(', ')}</span>,
          [ComponentList.DATE]: <DateFormat value={value} timezone={timezone} />,
          [ComponentList.DATE_TIME]: <DateTimeFormat value={value} timezone={componentProps?.timezone} />,
          [ComponentList.YES_OR_NO]: <YesOrNo value={value} />,
          [ComponentList.VENUE]: <Venue value={value} />,
          [ComponentList.VENUES]: <Venues value={value} />,
          [ComponentList.EDITABLE_CELL]: (
            <EditableCell row={row} value={value} columnId={columnId} setTableData={setTableData} onSave={onSave} />
          ),
          [ComponentList.EDITABLE_MONEY_CELL]: (
            <EditableMoneyCell
              row={row}
              value={value}
              setTableData={setTableData}
              columnId={columnId}
              onSave={onSave}
            />
          ),
          [ComponentList.PHONE_NUMBER]: <PhoneNumber value={value} />,
          [ComponentList.EMAIL]: <Email value={value} />,
          [ComponentList.EDITABLE_HOUR_CELL]: (
            <EditableHourCell row={row} value={value} columnId={columnId} setTableData={setTableData} onSave={onSave} />
          ),
          [ComponentList.HTML]: <span dangerouslySetInnerHTML={{ __html: value }} />,
          [ComponentList.PAYMENT_METHOD]: <PaymentMethod value={value} />,
          [ComponentList.FILE]: <File value={value} />,
          [ComponentList.LINK]: <Link value={value} />,
          [ComponentList.GOOD_OR_BAD]: <GoodOrBad value={value} />,
          [ComponentList.POSITIONS]: <Positions value={value} />,
          [ComponentList.PAYROLL_FEEDBACK]: <PayrollFeedback row={row} value={value} />,
          [ComponentList.YOUTUBE_TAGS]: <YoutubeTags value={value} />,
          [ComponentList.DOCS_TAGS]: <DocsTags value={value} />,
          [ComponentList.QUIZ_TAGS]: <QuizTags value={value} />,
          [ComponentList.DOWNLOAD_IMAGE]: <DownloadImage value={value} />,
          [ComponentList.NOTIFICATION_TRIGGER]: <NotificationTrigger value={value} />,
          [ComponentList.DOWNLOAD_RESUME]: <DownloadResume value={value} />,
          [ComponentList.VIEW_VIDEO]: <ViewVideo value={value} />,
          [ComponentList.APPLICATION_STATUS]: <ApplicationStatus value={value} />,
          [ComponentList.PRIMARY_TEXT]: <PrimaryText value={value} />
        }[type]
      }
    </React.Fragment>
  );
};

const Table = ({ handler }: { handler: useTableReturn }) => {
  const windowSize = useWindowSize();
  const {
    buttons,
    config,
    columns,
    columnSelects,
    defaultFilters,
    reloading,
    selectOptionColumn,
    searchable,
    search,
    setSearch,
    alternative,
    selected,
    loaded,
    isLoading,
    data,
    tableData,
    setTableData,
    limits,
    selectAll,
    menuButtons,
    setNewLimit,
    totalPages,
    toggleSelectAll,
    hideFooter,
    handleFilter,
    handleSelect,
    handleSort,
    handleRefresh,
    handleGoPrev,
    handleGoNext,
    handleColumnsSelect,
    handleHideColumn,
    onSave
  } = handler;
  const disabled = reloading || isLoading || !loaded;
  const prevDisabled = disabled || (!!tableData && tableData.page === 1);
  const nextDisabled = disabled || (!!tableData && tableData.page === totalPages) || totalPages === 0;
  const [target, setTarget] = useState<any>(null);

  return (
    <>
      {searchable && <SearchInToolbar value={search} onChange={setSearch} />}

      <div className={classnames('table', { alternative })}>
        <div className="wrapper" ref={setTarget}>
          <table className="body-2">
            <thead>
              <tr>
                {!!selected && (loaded || !!data) && (
                  <th className="check">
                    {selectAll ? (
                      <Checkbox
                        checked={(!data ? tableData.items : data).length === selected.value.length}
                        value="toggle-select"
                        onChange={toggleSelectAll}
                      />
                    ) : (
                      <> </>
                    )}
                  </th>
                )}
                {columns?.map(column => {
                  const { id, filter, width = 'auto', sortable = false, hidden } = column;
                  const header = 'header' in column ? column.header : undefined;

                  // @todo: mark as selected nested filters

                  return (
                    <th
                      key={id}
                      style={{
                        width,
                        minWidth: width !== 'auto' ? width : undefined,
                        display: hidden ? 'none' : undefined
                      }}
                    >
                      <div className="strong">
                        {!header ? (
                          <select
                            className={classnames('select-column', { marked: columnSelects[id] !== 'empty' })}
                            value={columnSelects[id]}
                            onChange={handleColumnsSelect(id)}
                          >
                            <option key="empty" value="empty">
                              -
                            </option>
                            {selectOptionColumn.map(column => (
                              <option key={column.key} value={column.key}>
                                {column.label}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <>{header}</>
                        )}
                        <div className="options">
                          {sortable && (
                            <ArrowDown2
                              onClick={handleSort(id)}
                              className={classnames(
                                'sort',
                                { selected: config.sort.column === id },
                                config.sort.column === id ? config.sort.order : sortBy.ASC
                              )}
                            />
                          )}
                          {!!filter && !!defaultFilters && (
                            <RMenu
                              portal={{ target }}
                              menuButton={
                                <Filter
                                  className={classnames('filter', {
                                    selected: !!config.filtersSelected[filter.key || id]
                                  })}
                                />
                              }
                              offsetX={16}
                              offsetY={8}
                              align="end"
                              position="anchor"
                              overflow="auto"
                            >
                              <MenuRadioGroup
                                value={
                                  config.filtersSelected[filter.key || id]
                                    ? config.filtersSelected[filter.key || id]
                                    : -1
                                }
                                onRadioChange={e => handleFilter(filter.key || id, e.value)}
                              >
                                <MenuItem key={-1} type="radio" value={-1}>
                                  All
                                </MenuItem>
                                {defaultFilters[filter.key || id].map(
                                  (item: { id: string; label: string }, index: number) => (
                                    <MenuItem key={index} type="radio" value={item.id}>
                                      {item.label}
                                    </MenuItem>
                                  )
                                )}
                              </MenuRadioGroup>
                            </RMenu>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                })}
                <th />
                {(!!menuButtons || !!buttons) && <th className="menu-buttons">{/*<ColumnsSwitcher />*/}</th>}
              </tr>
            </thead>

            <tbody>
              {!loaded && !data && (
                <tr className="loading">
                  <td>Loading...</td>
                </tr>
              )}
              {((!!tableData && !tableData.items.length) || (!!data && data.length === 0)) && (
                <tr className="loading">
                  <td>No items were found...</td>
                </tr>
              )}
              {(!!tableData || !!data) &&
                (!data ? tableData.items : data)?.map((row: any, indexRow: number) => (
                  <tr key={indexRow}>
                    {!!selected && (
                      <td className="check">
                        <Checkbox
                          value={row.id}
                          onChange={e => handleSelect(row, e.target.checked)}
                          checked={selected!.value.findIndex(e => e.id === row.id) > -1}
                        />
                      </td>
                    )}

                    {columns.map((column: any, indexCell) =>
                      column.hidden ? null : (
                        <td key={indexCell}>
                          <DynamicCell
                            columnId={column.id}
                            type={column.type}
                            row={row}
                            value={get(row, column.id)}
                            componentProps={column.componentProps}
                            timezone={get(row, 'venue.timezone')}
                            handleRefresh={handleRefresh}
                            setTableData={setTableData}
                            onSave={onSave!}
                          />
                        </td>
                      )
                    )}

                    <td />

                    {(!!menuButtons || !!buttons) && (
                      <td className="menu-buttons">
                        <div className="buttons-container">
                          {windowSize.width > TABLET_BREAKPOINT ? (
                            <>
                              {!!buttons &&
                                buttons.map((button, i) => (
                                  <Tooltip key={i} value={button.text}>
                                    <Button
                                      variant="alternative"
                                      icon={button.icon}
                                      onClick={() => button.onClick(row.id, row)}
                                      {...(!button.conditional ? {} : getConditions(row, button.conditional))}
                                    />
                                  </Tooltip>
                                ))}
                              {!!menuButtons && (
                                <DropdownButton
                                  id={row.id}
                                  params={row}
                                  isPortal
                                  button={<Button variant="alternative" icon={<More />} />}
                                  items={getMenuConditions(row, menuButtons)}
                                  align="end"
                                  viewScroll="close"
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <DropdownButton
                                id={row.id}
                                params={row}
                                isPortal
                                button={<Button variant="alternative" icon={<More />} />}
                                items={getMenuConditions(row, [...(buttons || []), ...(menuButtons || [])])}
                                align="end"
                                viewScroll="close"
                              />
                            </>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {!data && !hideFooter && (
          <footer>
            <div>
              <Button variant="alternative" small icon={<Refresh />} onClick={handleRefresh} disabled={reloading} />

              {!!selected && selected.value.length > 0 && (
                <div className="count-selected body-2">{selected.value.length} selected</div>
              )}
            </div>
            <div>
              <div className="hide-columns">
                <RMenu
                  menuButton={
                    <div className="action-item">
                      <FilterRemove />
                      <span className="body-2">Columns</span>
                    </div>
                  }
                >
                  {columns?.map((column: any) => {
                    return (
                      <div key={column.id} style={{ margin: 10 }}>
                        <Checkbox
                          value={column.id}
                          checked={!column.hidden}
                          label={column.header}
                          onChange={() => {
                            handleHideColumn(column.id);
                          }}
                        />
                      </div>
                    );
                  })}
                </RMenu>
              </div>

              {!!tableData && tableData.total > 0 && (
                <div className="info body-2">
                  {windowSize.width > MOBILE_BREAKPOINT
                    ? `${tableData.total} items - page ${tableData.page} of ${totalPages}`
                    : `${tableData.total} items - ${tableData.page}/${totalPages}`}
                </div>
              )}

              <DropdownButton
                button={
                  <Button
                    text={
                      windowSize.width > MOBILE_BREAKPOINT
                        ? `Items per page: ${config.limit}`
                        : `Limit: ${config.limit}`
                    }
                    variant="alternative"
                    small
                  />
                }
                items={limits.map(k => ({ text: `${k}`, onClick: setNewLimit(k) }))}
              />

              <Button
                variant="alternative"
                small
                icon={<ArrowLeft2 />}
                onClick={handleGoPrev}
                disabled={prevDisabled}
              />
              <Button
                variant="alternative"
                small
                icon={<ArrowRight2 />}
                onClick={handleGoNext}
                disabled={nextDisabled}
              />
            </div>
          </footer>
        )}
      </div>
    </>
  );
};

export default Table;
