import React from 'react';
import useWindowSize from '@revolt-digital/use-window-size';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { CloseSquare, Logout, HambergerMenu } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'constants/routes';
import { TABLET_BREAKPOINT } from 'constants/breakpoints';
import { useRefs } from 'hooks/useRefs';
import { Button, Logo } from 'components';
import { toggleSideMenu } from 'store/actions/general';
import { logout } from 'store/actions/user';
import { resetTables } from 'store/actions/table';
import { RootState } from 'types/store';

const Toolbar = () => {
  const windowSize = useWindowSize();
  const { toolbarMiddleRef, extraButtonsRef } = useRefs();
  const { mobileSideMenuOpen } = useSelector((state: RootState) => state.general);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetTables());
  };

  const handleToggleSideMenu = () => {
    dispatch(toggleSideMenu());
  };

  return (
    <div className={classnames('toolbar', { 'menu-open': mobileSideMenuOpen })}>
      <div>
        <Button
          className="menu-button"
          variant="alternative"
          icon={mobileSideMenuOpen ? <CloseSquare /> : <HambergerMenu />}
          onClick={handleToggleSideMenu}
        />
        <Link to={routes.ACCOUNTS} className="logo-link">
          <Logo full={windowSize.width > TABLET_BREAKPOINT} contrast={windowSize.width > TABLET_BREAKPOINT} />
        </Link>
      </div>
      <div className="middle-slot" ref={toolbarMiddleRef} />
      <div>
        <div className="extra-buttons-slot" ref={extraButtonsRef} />
        <Button variant="primary" small icon={<Logout />} onClick={handleLogout} />
      </div>
    </div>
  );
};

export default Toolbar;
