import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import routes from 'constants/routes';
import { RootState } from 'types/store';

const AccountsPage = lazy(() => import('../pages/accounts'));
const EarningsPage = lazy(() => import('../pages/earnings'));
const EmployeesPage = lazy(() => import('../pages/employees'));
const EmployeesOnBoardingPage = lazy(() => import('../pages/employeesOnBoarding'));
const EventPage = lazy(() => import('../pages/event'));
const EventsPage = lazy(() => import('../pages/events'));
const NotFoundPage = lazy(() => import('../pages/notFound'));
const NoticesPage = lazy(() => import('../pages/notices'));
const PaymentsPage = lazy(() => import('../pages/payments'));
const PayrollsPage = lazy(() => import('../pages/payrolls'));
const PositionsPage = lazy(() => import('../pages/positions'));
const StatesPage = lazy(() => import('../pages/states'));
const TagsPage = lazy(() => import('../pages/tags'));
const LoginPage = lazy(() => import('../pages/login'));
const UsersPage = lazy(() => import('../pages/users'));
const VenuesPage = lazy(() => import('../pages/venues'));
const AdminPayrollsPage = lazy(() => import('../pages/adminPayrolls'));
const SalariedPage = lazy(() => import('../pages/adminPayrolls/salaried'));
const HourlyPage = lazy(() => import('../pages/adminPayrolls/hourly'));
const ContractorPage = lazy(() => import('../pages/adminPayrolls/contractor'));
const AppealWarningPage = lazy(() => import('../pages/appealWarning'));
const TrainingsPage = lazy(() => import('../pages/trainings'));
const TemplatesPage = lazy(() => import('../pages/templates'));
const TemplatePage = lazy(() => import('../pages/template'));
const HumanResourcesPage = lazy(() => import('../pages/humanResources'));
const NotificationPage = lazy(() => import('../pages/notifications'));
const ApplicationPage = lazy(() => import('../pages/applications'));
const MyChatsPage = lazy(() => import('../pages/myChats'));

const SuspenseFallback: React.FC = () => <div>Loading...</div>;

const Anim: React.FC<{ component: React.ReactNode }> = ({ component }) => (
  <motion.div
    className="transition-page"
    initial="initial"
    animate="animate"
    exit="exit"
    variants={{
      initial: { opacity: 0 },
      animate: { opacity: 1, transition: { duration: 0.35 } },
      exit: { opacity: 0, transition: { duration: 0.15 } }
    }}
  >
    {component}
  </motion.div>
);

const Auth: React.FC<{ page: React.ReactElement }> = ({ page }) => {
  const { isLogged } = useSelector((state: RootState) => state.user);

  return isLogged ? page : <Navigate to={routes.LOGIN} replace />;
};

const Avoid: React.FC<{ page: React.ReactElement }> = ({ page }) => {
  const { isLogged } = useSelector((state: RootState) => state.user);
  return !isLogged ? page : <Navigate to={routes.ACCOUNTS} replace />;
};

const Router: React.FC = () => (
  <AnimatePresence exitBeforeEnter>
    <Routes>
      <Route
        path={routes.LOGIN}
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <Anim component={<Avoid page={<LoginPage />} />} />
          </Suspense>
        }
      />
      <Route
        path={routes.APPEAL_WARNING}
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <Anim component={<AppealWarningPage />} />
          </Suspense>
        }
      />
      <Route
        path={routes.ACCOUNTS}
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <Anim component={<Auth page={<AccountsPage />} />} />
          </Suspense>
        }
      />
      <Route
        path={routes.EARNINGS}
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <Anim component={<Auth page={<EarningsPage />} />} />
          </Suspense>
        }
      />
      <Route
        path={`${routes.EMPLOYEES}/:email?`}
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <Anim component={<Auth page={<EmployeesPage />} />} />
          </Suspense>
        }
      />
      <Route
        path={routes.EMPLOYEES_ON_BOARDING}
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <Anim component={<Auth page={<EmployeesOnBoardingPage />} />} />
          </Suspense>
        }
      />
      <Route
        path={routes.EVENT}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<EventPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.EVENTS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<EventsPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.HUMAN_RESOURCES}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<HumanResourcesPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.NOTICES}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<NoticesPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.PAYMENTS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<PaymentsPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.PAYROLLS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<PayrollsPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.ADMIN_PAYROLLS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<AdminPayrollsPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.ADMIN_PAYROLLS_SALARIED}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<SalariedPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.ADMIN_PAYROLLS_HOURLY}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<HourlyPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.ADMIN_PAYROLLS_CONTRACTOR}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<ContractorPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.POSITIONS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<PositionsPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.STATES}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<StatesPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.TAGS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<TagsPage />} />
              </Suspense>
            }
          />
        }
      />{' '}
      <Route
        path={routes.TRAININGS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<TrainingsPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.USERS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<UsersPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.VENUES}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<VenuesPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.TEMPLATES}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<TemplatesPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.TEMPLATE}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<TemplatePage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.NOTIFICATIONS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<NotificationPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.APPLICATIONS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<ApplicationPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path={routes.MY_CHATS}
        element={
          <Auth
            page={
              <Suspense fallback={<SuspenseFallback />}>
                <Anim component={<MyChatsPage />} />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<SuspenseFallback />}>
            <Anim component={<NotFoundPage />} />
          </Suspense>
        }
      />
    </Routes>
  </AnimatePresence>
);

export default Router;
