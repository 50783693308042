/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useState } from 'react';
import { get, set, remove, pullAt } from 'lodash';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { useModal } from 'hooks/useModal';
import { IArea, ISitelead, IPosition } from 'types/event';
import { ModalsList } from 'types/modals';
import { JobType } from 'constants/jobType';
import { getAssignedWorkers, getPositionName, getStandName, getWorkersNeeded } from 'helpers/jobs';

type IJob = {
  id: number;
  areas: IArea[];
  siteLeads: ISitelead[];
  positions: IPosition[];
  positionCapacity: number;
};

type IAssignable = 'siteLeader' | 'areaLeader' | 'standLeader' | 'staff';

export type IHookValues = {
  id: number;
  areas: IArea[];
  positions: IPosition[];
  siteLeads: ISitelead[];
  update: (job: IJob) => void;
  assignSiteLeader: () => void;
  assignAreaLeader: (areaId: number, areaIndex: number) => () => void;
  assignStandLeader: (standId: number, areaIndex: number, standIndex: number) => () => void;
  allocations: (
    standId: number,
    positionId: number,
    areaIndex: number,
    standIndex: number,
    positionIndex: number,
    handleRefresh: () => void,
    locationPositionId?: number
  ) => () => void;
  addArea: () => void;
  addStand: (areaId: number) => () => void;
  addStandPosition: (
    standId: number,
    areaIndex: number,
    standIndex: number,
    positionCapacity: number,
    handleRefresh: () => void
  ) => () => void;
  deleteArea: (areaId: number) => () => void;
  deleteStand: (areaId: number, standId: number, areaIndex: number, standIndex: number) => () => void;
  editAreaName: (areaId: number, areaIndex: number) => () => void;
  editStandName: (standId: number, areaIndex: number, standIndex: number) => () => void;
  editAreaPoolableTipPercentage: (areaId: number, areaIndex: number) => () => void;
};

const JobsContext = createContext({} as IHookValues);

export const useJobs = () => {
  const context = useContext(JobsContext);
  return context;
};

export default ({ children }: { children: React.ReactNode }) => {
  const api = useApi();
  const modal = useModal();
  const [data, setData] = useState<IJob>({ id: -1, areas: [], siteLeads: [], positions: [], positionCapacity: 0 });

  const handleRefresh = async () => {
    const r = await api.fetchData(endpoints.GET_EVENT.get({ id: data.id }));

    setData({
      ...data,
      areas: r.areas,
      siteLeads: r.data.siteleads,
      positions: r.positions,
      positionCapacity: r.data.positionCapacity
    });
  };

  const checkAlreadyAssigned = (willBeAssignedAs: IAssignable, employees: string[]) => {
    const siteLeaderIds = data.siteLeads.flatMap(item => item.jobs.map(job => job.employee.id));
    const areaLeaderIds = data.areas.flatMap(area =>
      area.locationsPositions.flatMap(item => item.jobs.map(job => job.employee.id))
    );
    const standLeaderIds = data.areas.flatMap(area =>
      area.stands.flatMap(stand =>
        stand.positions
          .filter(position => position.job_type === JobType.STAND_LEAD)
          .flatMap(position => position.jobs.map(job => job.employee.id))
      )
    );
    const staffIds = data.areas.flatMap(area =>
      area.stands.flatMap(stand =>
        stand.positions
          .filter(position => position.job_type === JobType.STAFF)
          .flatMap(position => position.jobs.map(job => job.employee.id))
      )
    );

    const isAlreadyAssigned = (ids: string[]) => ids.some(id => employees.includes(id));

    switch (willBeAssignedAs) {
      case 'siteLeader':
        return isAlreadyAssigned(areaLeaderIds) || isAlreadyAssigned(standLeaderIds) || isAlreadyAssigned(staffIds);
      case 'areaLeader':
        return isAlreadyAssigned(siteLeaderIds) || isAlreadyAssigned(standLeaderIds) || isAlreadyAssigned(staffIds);
      case 'standLeader':
        return isAlreadyAssigned(siteLeaderIds) || isAlreadyAssigned(areaLeaderIds) || isAlreadyAssigned(staffIds);
      case 'staff':
        return (
          isAlreadyAssigned(siteLeaderIds) || isAlreadyAssigned(areaLeaderIds) || isAlreadyAssigned(standLeaderIds)
        );
      default:
        return false;
    }
  };

  // const checkAlreadyAssigned = (willBeAssignedAs: string, employees: number[]) => {
  //   const siteLeaderIds = data.siteLeads.flatMap((item: any) => item.jobs.map((job: any) => job.employee.id));
  //   const areaLeaderIds = data.areas.flatMap((area: any) =>
  //     area.locationsPositions.flatMap((item: any) => item.jobs.map((job: any) => job.employee.id))
  //   );
  //   const standLeaderIds = data.areas.flatMap((area: any) =>
  //     area.stands.flatMap((stand: any) =>
  //       stand.positions
  //         .filter((item: any) => item.job_type === JobType.STAND_LEAD)
  //         .flatMap((item: any) => item.jobs.map((job: any) => job.employee.id))
  //     )
  //   );

  //   const staffIds = data.areas.flatMap((area: any) =>
  //     area.stands.flatMap((stand: any) =>
  //       stand.positions
  //         .filter((item: any) => item.job_type === JobType.STAFF)
  //         .flatMap((item: any) => item.jobs.map((job: any) => job.employee.id))
  //     )
  //   );

  //   if (willBeAssignedAs === 'siteLeader') {
  //     const assignedAsArealeader = areaLeaderIds.every((item: any) => employees.includes(item));
  //     const assignedAsStandleader = standLeaderIds.every((item: any) => employees.includes(item));
  //     const assignedAsStaff = staffIds.every((item: any) => employees.includes(item));

  //     return assignedAsArealeader || assignedAsStandleader || assignedAsStaff;
  //   }

  //   if (willBeAssignedAs === 'areaLeader') {
  //     const assignedAsSiteleader = siteLeaderIds.every((item: any) => employees.includes(item));
  //     const assignedAsStandleader = standLeaderIds.every((item: any) => employees.includes(item));
  //     const assignedAsStaff = staffIds.every((item: any) => employees.includes(item));

  //     return assignedAsStandleader || assignedAsSiteleader || assignedAsStaff;
  //   }

  //   if (willBeAssignedAs === 'standLeader') {
  //     const assignedAsSiteleader = siteLeaderIds.every((item: any) => employees.includes(item));
  //     const assignedAsArealeader = areaLeaderIds.every((item: any) => employees.includes(item));
  //     const assignedAsStaff = staffIds.every((item: any) => employees.includes(item));

  //     return assignedAsSiteleader || assignedAsArealeader || assignedAsStaff;
  //   }

  //   if (willBeAssignedAs === 'staff') {
  //     const assignedAsSiteleader = siteLeaderIds.every((item: any) => employees.includes(item));
  //     const assignedAsArealeader = areaLeaderIds.every((item: any) => employees.includes(item));
  //     const assignedAsStandleader = standLeaderIds.every((item: any) => employees.includes(item));

  //     return assignedAsSiteleader || assignedAsArealeader || assignedAsStandleader;
  //   }

  //   return true;
  // };

  const assignSiteLeader = async () => {
    await modal.open(
      ModalsList.ASSIGN_SITE_LEADER_MODAL,
      async positions => {
        if (positions) {
          await api.fetchData(endpoints.ASSIGN_SITE_LEADER.get({ id: data.id }), { positions }, true);
          handleRefresh();
        }
      },
      { checkAlreadyAssigned, leaders: data.siteLeads }
    );
  };

  const assignAreaLeader = (areaId: number, areaIndex: number) => () => {
    modal.open(
      ModalsList.ASSIGN_AREA_LEADER_MODAL,
      async positions => {
        if (positions) {
          await api.fetchData(endpoints.ASSIGN_AREA_LEADER.get({ id: data.id }), { areaId, positions }, true);
          handleRefresh();
        }
      },
      { checkAlreadyAssigned, leaders: get(data, `areas[${areaIndex}].locationsPositions`) }
    );
  };

  const assignStandLeader = (standId: number, areaIndex: number, standIndex: number) => () => {
    modal.open(
      ModalsList.ASSIGN_STAND_LEADER_MODAL,
      async positions => {
        if (positions) {
          const r = await api.fetchData(
            endpoints.ASSIGN_STAND_LEADER.get({ id: data.id }),
            { standId, positions },
            true
          );
          handleRefresh();
        }
      },
      { checkAlreadyAssigned, leaders: get(data, `areas[${areaIndex}].stands[${standIndex}].positions`) }
    );
  };

  const allocations =
    (
      standId: number,
      positionId: number,
      areaIndex: number,
      standIndex: number,
      _positionIndex: number,
      handleRefresh: () => void,
      locationPositionId?: number
    ) =>
    () => {
      const { id } = data;

      modal.open(
        ModalsList.ALLOCATE_IN_STAND_POSITION,
        async payload => {
          if (payload) {
            const { workersNeeded, employees } = payload;
            const params = { employees, workersNeeded, positionId, standId };
            await api.fetchData(endpoints.ASSIGN_INTO_STAND_POSITION.get({ id }), params, true);
            handleRefresh();
          }
        },
        {
          eventId: id,
          standId,
          positionId,
          workersNeeded: getWorkersNeeded({ data, areaIndex, standIndex, positionId }),
          handleRemove: deleteStandPosition(standId, positionId, handleRefresh, locationPositionId!),
          checkAlreadyAssigned,
          assigned: getAssignedWorkers({ data, areaIndex, standIndex, positionId }),
          standName: getStandName({ data, areaIndex, standIndex }),
          positionName: getPositionName({ data, areaIndex, standIndex, positionId }),
          positionCapacity: get(data, 'positionCapacity')
        }
      );
    };

  const deleteArea = (areaId: number) => async () => {
    await api.fetchData(endpoints.DELETE_AREA.get({ id: areaId }), null, true);
    handleRefresh();
  };

  const deleteStand = (_areaId: number, standId: number) => async () => {
    await api.fetchData(endpoints.DELETE_STAND.get({ id: standId }), null, true);
    handleRefresh();
  };

  const deleteStandPosition =
    (standId: number, positionId: number, handleRefresh: () => void, locationPositionId: number) => async () => {
      await api.fetchData(endpoints.DELETE_LOCATION_POSITION.get({ id: locationPositionId }), null, true);
      handleRefresh();
    };

  const addArea = () => {
    modal.open(ModalsList.CREATE_AREA_MODAL, async payload => {
      if (payload) {
        await api.fetchData(endpoints.CREATE_AREA.get(), { eventId: data.id, name: payload.name }, true);
        handleRefresh();
      }
    });
  };

  const addStand = (areaId: number) => () => {
    modal.open(ModalsList.CREATE_STAND_MODAL, async payload => {
      if (payload) {
        await api.fetchData(endpoints.CREATE_STAND.get(), { areaId, name: payload.name });
        handleRefresh();
      }
    });
  };

  const addStandPosition =
    (standId: number, areaIndex: number, standIndex: number, positionCapacity: number, handleRefresh: () => void) =>
    () => {
      const positionsSet = get(data, `areas[${areaIndex}].stands[${standIndex}].positions`);
      const positions = data.positions.filter(
        (item: any) => !positionsSet.find((position: any) => position.position_id === item.id)
      );

      modal.open(
        ModalsList.CREATE_STAND_POSITION_MODAL,
        async payload => {
          if (payload) {
            await api.fetchData(
              endpoints.CREATE_STAFF.get(),
              { standId, positionId: payload.position, total: payload.total },
              true
            );
            handleRefresh();
          }
        },
        {
          positions,
          positionCapacity
        }
      );
    };

  const editAreaName = (areaId: number, areaIndex: number) => () => {
    modal.open(
      ModalsList.EDITION_AREA_NAME_MODAL,
      async payload => {
        if (payload) {
          await api.fetchData(endpoints.EDIT_AREA_NAME.get({ id: areaId }), { name: payload.name }, true);
          handleRefresh();
        }
      },
      { name: get(data, `areas[${areaIndex}].name`) }
    );
  };

  const editAreaPoolableTipPercentage = (areaId: number, areaIndex: number) => () => {
    modal.open(
      ModalsList.EDITION_AREA_POOLABLE_TIP_PERCENTAGE_MODAL,
      async payload => {
        if (payload) {
          await api.fetchData(
            endpoints.EDIT_AREA_POOLABLE_TIP_PERCENTAGE.get({ id: areaId }),
            { poolableTipPercentage: payload.poolableTipPercentage },
            true
          );
          handleRefresh();
        }
      },
      {
        percentage: get(data, `areas[${areaIndex}].poolable_tip_percentage`)
      }
    );
  };

  const editStandName = (standId: number, areaIndex: number, standIndex: number) => () => {
    modal.open(
      ModalsList.EDITION_STAND_NAME_MODAL,
      async payload => {
        if (payload) {
          await api.fetchData(endpoints.EDIT_STAND_NAME.get({ id: standId }), { name: payload.name }, true);
          handleRefresh();
        }
      },
      { name: get(data, `areas[${areaIndex}].stands[${standIndex}].name`) }
    );
  };

  const update = (data: IJob) => {
    setData(data);
  };

  return (
    <JobsContext.Provider
      value={{
        id: data.id,
        areas: data.areas,
        siteLeads: data.siteLeads,
        positions: data.positions,
        update,
        assignSiteLeader,
        assignAreaLeader,
        assignStandLeader,
        deleteArea,
        deleteStand,
        allocations,
        addArea,
        addStand,
        addStandPosition,
        editAreaName,
        editStandName,
        editAreaPoolableTipPercentage
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};
