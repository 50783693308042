import { createStore } from 'redux';
import { getPersistConfig } from 'redux-deep-persist';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

const persistConfig = getPersistConfig({
  key: 'panel-root-1',
  storage,
  blacklist: ['general.mobileSideMenuOpen', 'user.channelUrl'],
  rootReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
