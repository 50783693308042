import React, { useRef } from 'react';
import ReactQuill from 'react-quill';

type Props = {
  value: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  onChange?: any;
  onBlur?: () => void;
  readOnly?: boolean;
};

const TOOLBAR_OPTIONS = [['bold', 'italic', 'underline', 'link']];

const RichText = ({ value, placeholder, label, required, onChange, onBlur, readOnly = false }: Props) => {
  const ref = useRef<any>();

  return (
    <div className="reach-text-container">
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <ReactQuill
        ref={ref}
        onBlur={onBlur}
        placeholder={placeholder}
        readOnly={readOnly}
        theme={readOnly ? 'bubble' : undefined}
        modules={{
          toolbar: {
            container: TOOLBAR_OPTIONS,
            formats: []
          }
        }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default RichText;
