import { tooltipColors } from 'constants/tooltipColors';
import React from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';

type Props = {
  children: React.ReactNode;
  value: string | string[];
  fixed?: boolean;
  disabled?: boolean;
  variant?: 'dark' | 'primary';
  hidden?: boolean;
};

const Tooltip = ({ children, value, variant = 'dark', hidden = false }: Props) => {
  const getColor = () => {
    return variant === 'dark' ? tooltipColors.darkest : tooltipColors.primary;
  };

  const style = {
    style: {
      background: getColor(),
      color: tooltipColors.lightest,
      display: hidden ? 'none' : 'block'
    },
    arrowStyle: {
      borderBottomColor: getColor()
    }
  };

  return (
    <StatefulToolTip
      style={style}
      parent={children}
      position="bottom"
      arrow="center"
      group={value}
      tooltipTimeout={0}
      useHover={false}
    >
      {Array.isArray(value) ? (
        <div>
          {value.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      ) : (
        value
      )}
    </StatefulToolTip>
  );
};

export default Tooltip;
