import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  abbreviation: {
    value: '',
    label: 'Abbreviation',
    limit: 20,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  alcoholInstructionsUrl: {
    value: '',
    label: 'Alcohol Instructions URL',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().uri().allow(null).allow('').optional().messages({
      'string.uri': formErrors.INVALID_URL
    })
  },
  backgroundCheck: {
    value: false,
    label: 'Background check required?',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  }
};
