import { endpointsPrototype } from 'helpers/data';
import { Methods } from 'hooks/useApi';

const endpoints: any = {
  LOGIN: { endpoint: '/auth/panel/login', method: Methods.POST },
  REGISTER: { endpoint: '/auth/register', method: Methods.POST },
  VERIFY: { endpoint: '/auth/email/verify', method: Methods.POST },
  RESET_PASSWORD: { endpoint: '/auth/reset_password', method: Methods.POST },
  ADD_REGISTERED_EMPLOYEE: { endpoint: '/events/register_employees/:id', method: Methods.POST },
  ASSIGN_INTO_STAND_POSITION: { endpoint: '/staff/assign', method: Methods.POST },
  ASSIGN_SITE_LEADER: { endpoint: '/events/:id/assign/siteleader', method: Methods.POST },
  ASSIGN_AREA_LEADER: { endpoint: '/areas/assign/arealeader', method: Methods.POST },
  ASSIGN_STAND_LEADER: { endpoint: '/stands/assign/standleader', method: Methods.POST },
  EXPORT_EMPLOYEES: { endpoint: '/employees/export', method: Methods.POST },
  EXPORT_EMPLOYEE_ONBOARDING_DATA: { endpoint: '/onboarding/export', method: Methods.POST },
  EXPORT_BLANK_ATTENDANCE: { endpoint: '/events/:id/attendance', method: Methods.POST },
  EXPORT_ROSTER: { endpoint: '/events/:id/roster', method: Methods.POST },
  EXPORT_REGISTERED: { endpoint: '/events/:id/registered_sheet', method: Methods.POST },
  CONFIRM_RESET_PASSWORD: { endpoint: '/auth/confirm_reset_password', method: Methods.POST },
  VERIFY_TOKEN: { endpoint: '/auth/verify_token', method: Methods.POST },
  CONSULT_PAYROLL: { endpoint: '/payrolls/url/:id', method: Methods.GET },
  GET_ACCOUNT: { endpoint: '/accounts/:id', method: Methods.GET },
  GET_EVENT_SUBSCRIBED: { endpoint: '/events/subscribed/:id?paginated=:paginated', method: Methods.POST },
  GET_INCIDENT_REPORTS: { endpoint: '/incidents/event/:id', method: Methods.POST },
  GET_INVENTORY: { endpoint: '/inventories/list/:id', method: Methods.GET },
  GET_ONBOARDING: { endpoint: '/onboarding/:id', method: Methods.GET },
  GET_VALID_STATES: { endpoint: '/states', method: Methods.GET },
  GET_VENUE: { endpoint: '/venues/admin/:id', method: Methods.GET },
  GET_VENUE_RATE_AND_TIPS: { endpoint: '/venues/:id/positions', method: Methods.GET },
  GET_PAYROLL: { endpoint: '/payrolls/:id', method: Methods.GET },
  GET_PAYMENT: { endpoint: '/payments/:id', method: Methods.GET },
  GET_EARNING: { endpoint: '/earnings/:id', method: Methods.GET },
  GET_EMPLOYEE: { endpoint: '/employees/:id', method: Methods.GET },
  GET_USER: { endpoint: '/users/:id', method: Methods.GET },
  GET_EVENT: { endpoint: '/events/:id', method: Methods.GET },
  GET_EVENT_TIPS: { endpoint: '/events/tips/:id', method: Methods.POST },
  GET_LOCATION: { endpoint: '/locations/:id', method: Methods.GET },
  GET_LOCATIONS_BY_VENUE: { endpoint: '/locations/venue/:id', method: Methods.POST },
  GET_NOTICE: { endpoint: '/notices/:id', method: Methods.GET },
  GET_POSITION: { endpoint: '/positions/:id', method: Methods.GET },
  GET_TAG: { endpoint: '/tags/:id', method: Methods.GET },
  GET_TAGS: { endpoint: '/tags/list', method: Methods.GET },
  GET_ACTIVE_STATES: { endpoint: '/states', method: Methods.GET },
  GET_STATE: { endpoint: '/states/:id', method: Methods.GET },
  GET_WARNING: { endpoint: '/warnings/:id', method: Methods.GET },
  GET_WARNING_ARCHIVED: { endpoint: '/warnings/archived/:id', method: Methods.GET },
  GET_APPEAL_ARCHIVED: { endpoint: '/warnings/appeals/archived/:id', method: Methods.GET },
  GET_TERMINATION_ARCHIVED: { endpoint: '/warnings/terminations/archived/:id', method: Methods.GET },
  GET_WARNING_CODE: { endpoint: '/warnings/code/:code', method: Methods.GET },
  GET_APPEAL: { endpoint: '/warnings/appeals/:id', method: Methods.GET },
  GET_TERMINATION: { endpoint: '/warnings/terminations/:id', method: Methods.GET },
  GET_TERMINATION_WARNINGS: { endpoint: '/warnings/terminations/warnings-by-user/:id', method: Methods.GET },
  GET_PAYROLL_FEEDBACK: { endpoint: '/payrolls/feedback/:id', method: Methods.GET },
  CREATE_ACCOUNT: { endpoint: '/accounts/create', method: Methods.POST },
  CREATE_AREA: { endpoint: '/areas/create', method: Methods.POST },
  CREATE_EVENT: { endpoint: '/events/create', method: Methods.POST },
  CREATE_NOTICE: { endpoint: '/notices/create', method: Methods.POST },
  CREATE_LOCATION: { endpoint: '/locations/create', method: Methods.POST },
  CREATE_STAND: { endpoint: '/stands/create', method: Methods.POST },
  CREATE_TAG: { endpoint: '/tags/create', method: Methods.POST },
  CREATE_VENUE: { endpoint: '/venues/create', method: Methods.POST },
  CREATE_PAYROLL: { endpoint: '/payrolls/create', method: Methods.POST },
  CREATE_STAND_POSITION: { endpoint: '/standspositions/create', method: Methods.POST },
  CREATE_POSITION: { endpoint: '/positions/create', method: Methods.POST },
  CREATE_WARNING: { endpoint: '/warnings/create', method: Methods.POST },
  CREATE_APPEAL: { endpoint: '/warnings/appeals/create', method: Methods.POST },
  CREATE_TERMINATION: { endpoint: '/warnings/terminations/create', method: Methods.POST },
  EDIT_ACCOUNT: { endpoint: '/accounts/edit/:id', method: Methods.PUT },
  EDIT_AREA_NAME: { endpoint: '/areas/edit/:id', method: Methods.PUT },
  EDIT_CHECK_IN_OUT: { endpoint: '/jobs/check_in_out/:id', method: Methods.PUT },
  EDIT_EARNING: { endpoint: '/earnings/edit/:id', method: Methods.PUT },
  EDIT_EVENT: { endpoint: '/events/edit/:id', method: Methods.PUT },
  EDIT_EVENT_END_SALES: { endpoint: '/events/end_sales', method: Methods.PUT },
  EDIT_EVENT_START_SALES: { endpoint: '/events/start_sales', method: Methods.PUT },
  EDIT_EVENT_TIPS: { endpoint: '/events/tips/save', method: Methods.POST },
  EDIT_LOCATION: { endpoint: '/locations/edit/:id', method: Methods.PUT },
  EDIT_USER: { endpoint: '/users/edit/:id', method: Methods.PUT },
  EDIT_EMPLOYEE: { endpoint: '/employees/edit/:id', method: Methods.PUT },
  EDIT_NOTICE: { endpoint: '/notices/edit/:id', method: Methods.PUT },
  EDIT_PAYROLL: { endpoint: '/payrolls/edit/:id', method: Methods.PUT },
  EDIT_POSITION: { endpoint: '/positions/edit/:id', method: Methods.PUT },
  EDIT_TAG: { endpoint: '/tags/edit/:id', method: Methods.PUT },
  EDIT_STAND_NAME: { endpoint: '/stands/edit/:id', method: Methods.PUT },
  EDIT_STATE: { endpoint: '/states/edit/:id', method: Methods.PUT },
  EDIT_VENUE: { endpoint: '/venues/edit/:id', method: Methods.PUT },
  EDIT_VENUES_RATES: { endpoint: '/venues/:id/rates', method: Methods.POST },
  EDIT_VENUES_FOR_EMPLOYEE: { endpoint: '/employees/edit/:id/venues', method: Methods.PUT },
  DELETE_AREA: { endpoint: '/areas/delete/:id', method: Methods.DELETE },
  DELETE_STAND: { endpoint: '/stands/delete/:id', method: Methods.DELETE },
  DELETE_STAND_POSITION: { endpoint: '/standspositions/delete', method: Methods.DELETE },
  EXECUTE_PAYROLL: { endpoint: '/payrolls/process/:id', method: Methods.POST },
  SEARCH_EMPLOYEE: { endpoint: '/employees/search', method: Methods.GET },
  SEARCH_EMPLOYEE_FOR_REGISTRATION: { endpoint: '/employees/search/:id', method: Methods.GET },
  SEARCH_USER: { endpoint: '/users/search', method: Methods.GET },
  SEARCH_EVENT: { endpoint: '/events/search', method: Methods.GET },
  SEARCH_ACCOUNT: { endpoint: '/accounts/search', method: Methods.GET },
  SEARCH_VENUE: { endpoint: '/venues/search', method: Methods.GET },
  SEARCH_PAYMENT: { endpoint: '/payments/search', method: Methods.GET },
  SEARCH_POSITION: { endpoint: '/positions/search', method: Methods.GET },
  SEARCH_ALL_POSITIONS: { endpoint: '/positions/search/all', method: Methods.GET },
  SEARCH_TAGS: { endpoint: '/tags/search', method: Methods.GET },
  SEARCH_VENUES: { endpoint: '/venues/search', method: Methods.GET },
  EXPORT_PAYROLL: { endpoint: '/payrolls/export/:id', method: Methods.POST },
  IMPORT_PAYROLL: { endpoint: '/payrolls/import', method: Methods.POST },
  FILE_NOTICE: { endpoint: '/notices/files/signed_url', method: Methods.POST },
  FILE_APPEAL: { endpoint: '/warnings/files/signed_url', method: Methods.POST },
  FILE_TRAINING: { endpoint: '/trainings/files/signed_url', method: Methods.POST },
  UNREGISTER_EMPLOYEE: { endpoint: '/events/:id/unregister', method: Methods.POST },
  GET_EMPLOYEE_NOTES: { endpoint: '/events/:eventId/notes/:employeeId', method: Methods.GET },
  GET_CERTIFICATION_URL: { endpoint: '/employees/certification/:employeeId', method: Methods.GET },
  EDIT_CERTIFICATION_STATUS: { endpoint: '/employees/edit/certification', method: Methods.PUT },
  BAN_EMPLOYEE: { endpoint: '/employees/disable/:id', method: Methods.POST },
  UNBAN_EMPLOYEE: { endpoint: '/employees/enable/:id', method: Methods.POST },
  DELETE_USER: { endpoint: '/users/:id', method: Methods.DELETE },
  DELETE_EVENT: { endpoint: '/events/:id', method: Methods.DELETE },
  DOWNLOAD_EMPLOYEES: { endpoint: '/events/:id/employees', method: Methods.POST },
  EMPLOYEES_STATS: { endpoint: '/employees/statistics', method: Methods.POST },
  EDIT_AREA_POOLABLE_TIP_PERCENTAGE: { endpoint: '/areas/poolable/:id', method: Methods.PUT },
  EDIT_EVENT_TAGS: { endpoint: '/events/tags/:id', method: Methods.PUT },
  GET_VENUE_STATS: { endpoint: '/venues/statistics', method: Methods.POST },
  GET_EVENT_STATS: { endpoint: '/events/statistics', method: Methods.POST },
  GET_NEXT_PAYDAY: { endpoint: '/payrolls_admin/next_payday/:type', method: Methods.GET },
  CREATE_ADMIN_PAYROLL: { endpoint: '/payrolls_admin/create', method: Methods.POST },
  EDIT_ADMIN_PAYROLL: { endpoint: '/payrolls_admin/edit', method: Methods.PUT },
  DELETE_ADMIN_PAYROLL: { endpoint: '/payrolls_admin/delete/:id', method: Methods.DELETE },
  CREATE_ADMIN_PAYROLL_ITEM: { endpoint: '/payrolls_admin/item/create', method: Methods.POST },
  EDIT_ADMIN_PAYROLL_ITEM: { endpoint: '/payrolls_admin/item/edit', method: Methods.PUT },
  DELETE_ADMIN_PAYROLL_ITEM: { endpoint: '/payrolls_admin/delete/item/:id', method: Methods.DELETE },
  EXECUTE_ADMIN_PAYROLL: { endpoint: '/payrolls_admin/execute/:id', method: Methods.POST },
  GET_PAYROLL_URL: { endpoint: '/payrolls_admin/url/:id', method: Methods.GET },
  REOPEN_ADMIN_PAYROLL: { endpoint: '/payrolls_admin/reopen/:id', method: Methods.POST },
  ACCEPT_WARNING: { endpoint: '/warnings/accept', method: Methods.POST },
  REJECT_WARNING: { endpoint: '/warnings/reject', method: Methods.POST },
  ACCEPT_APPEAL: { endpoint: '/warnings/appeals/accept', method: Methods.POST },
  REJECT_APPEAL: { endpoint: '/warnings/appeals/reject', method: Methods.POST },
  ACCEPT_TERMINATION: { endpoint: '/warnings/terminations/accept', method: Methods.POST },
  REJECT_TERMINATION: { endpoint: '/warnings/terminations/reject', method: Methods.POST },
  EDIT_ADMIN_EVENT: { endpoint: '/events/admin/:id', method: Methods.PUT },
  CREATE_TRAINING: { endpoint: '/trainings/create', method: Methods.POST },
  GET_TRAINING: { endpoint: '/trainings/:id', method: Methods.GET },
  EDIT_TRAINING: { endpoint: '/trainings/edit/:id', method: Methods.PUT },
  GET_EMPLOYEE_UNIFORMS: { endpoint: '/employees/:employeeId/uniform', method: Methods.GET },
  CREATE_TEMPLATE: { endpoint: '/templates/create', method: Methods.POST },
  GET_TEMPLATE: { endpoint: '/templates/:id', method: Methods.GET },
  GET_TEMPLATES: { endpoint: '/templates/list', method: Methods.POST },
  CREATE_LOCATIONS_POSITIONS: { endpoint: '/locations_positions/create', method: Methods.POST },
  DELETE_TEMPLATE: { endpoint: '/templates/delete/:id', method: Methods.DELETE },
  DUPLICATE_TEMPLATE: { endpoint: '/templates/duplicate/:id', method: Methods.POST },
  EDIT_TEMPLATE_DETAILS: { endpoint: '/templates/edit/:id', method: Methods.PUT },
  DELETE_LOCATION_POSITION: { endpoint: '/locations_positions/delete/:id', method: Methods.DELETE },
  EDIT_LOCATION_POSITION: { endpoint: '/locations_positions/edit/:id', method: Methods.PUT },
  GET_EVENTS_FOR_MONTH: { endpoint: '/events/list-for-month', method: Methods.POST },
  SEARCH_TEMPLATES: { endpoint: '/templates/search/:venueId', method: Methods.GET },
  CREATE_STAFF: { endpoint: '/staff/create', method: Methods.POST },
  JOIN_CHAT: { endpoint: '/chat/join', method: Methods.POST },
  CREATE_NOTIFICATION: { endpoint: '/notifications/create', method: Methods.POST },
  GET_NOTIFICATION: { endpoint: '/notifications/:id', method: Methods.GET },
  SEND_REMINDER: { endpoint: '/applications/:applicationId/reminder', method: Methods.POST },
  GET_APPLICATION_ADMIN: { endpoint: '/applications/admin/:applicationId', method: Methods.GET },
  APPROVE_APPLICATION: { endpoint: '/applications/:applicationId/approve', method: Methods.POST },
  REJECT_APPLICATION: { endpoint: '/applications/:applicationId/reject', method: Methods.POST },
  GET_SETTINGS_APPLICATIONS: { endpoint: '/settings/applications', method: Methods.GET },
  ENABLE_APPLICATIONS: { endpoint: '/settings/applications', method: Methods.POST }
};

Object.assign(endpoints, endpointsPrototype);
endpoints.init();

export default endpoints;
