import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';

export enum ComponentList {
  ACCOUNT = 'ACCOUNT',
  ALCOHOL_CERTIFICATION_STATUS = 'ALCOHOL_CERTIFICATION_STATUS',
  ALCOHOL_CERTIFICATION_STATUS_TAG = 'ALCOHOL_CERTIFICATION_STATUS_TAG',
  BACKGROUND_CHECK_STATUS = 'BACKGROUND_CHECK_STATUS',
  MONEY = 'MONEY',
  TEXT = 'TEXT',
  TEXT_COPY = 'TEXT_COPY',
  LIST = 'LIST',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  YES_OR_NO = 'YES_OR_NO',
  VENUE = 'VENUE',
  VENUES = 'VENUES',
  EDITABLE_CELL = 'EDITABLE_CELL',
  EDITABLE_MONEY_CELL = 'EDITABLE_MONEY_CELL',
  EDITABLE_HOUR_CELL = 'EDITABLE_HOUR_CELL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  HTML = 'HTML',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  FILE = 'FILE',
  LINK = 'LINK',
  GOOD_OR_BAD = 'GOOD_OR_BAD',
  POSITIONS = 'POSITIONS',
  PAYROLL_FEEDBACK = 'PAYROLL_FEEDBACK',
  YOUTUBE_TAGS = 'YOUTUBE_TAGS',
  DOCS_TAGS = 'DOCS_TAGS',
  QUIZ_TAGS = 'QUIZ_TAGS',
  UNIFORM_APPROVAL = 'UNIFORM_APPROVAL',
  DOWNLOAD_IMAGE = 'DOWNLOAD_IMAGE',
  NOTIFICATION_TRIGGER = 'NOTIFICATION_TRIGGER',
  DOWNLOAD_RESUME = 'DOWNLOAD_RESUME',
  VIEW_VIDEO = 'VIEW_VIDEO',
  APPLICATION_STATUS = 'APPLICATION_STATUS',
  PRIMARY_TEXT = 'PRIMARY_TEXT'
}

export enum FilterTypes {
  DATE = 'DATE',
  SELECT = 'SELECT',
  MULTI = 'MULTI'
}

export type SelectOptionColumn = {
  label: string;
  key: string;
};

export type TableColumn = {
  id: string;
  header?: string;
  width?: string;
  type: ComponentList;
  mask?: any;
  sortable?: boolean;
  filter?: {
    type: FilterTypes;
    key?: string;
    endpoint?: string;
    options?: string;
  };
  componentProps?: any;
  hidden?: boolean;
};

export type MenuButton = {
  text: string;
  icon?: React.ReactElement;
  onClick: (id?: string | number, params?: any) => void;
  conditional?: Condition[];
};

export type Condition = {
  property: string;
  value: (item: any) => any;
};

export type TableButton = {
  text: string;
  icon?: React.ReactElement;
  onClick: (id?: string | number, params?: any) => void;
  conditional?: Condition[];
};

export type Sort = {
  column: string;
  order: 'asc' | 'desc';
};

export type Config = {
  sort: Sort;
  limit: number;
  page: number;
  filtersSelected: any;
};

export type TableOptions = {
  columns: TableColumn[];
  data?: any[];
  endpoint?: string;
  itemsPerPage?: number;
  menuButtons?: MenuButton[];
  buttons?: TableButton[];
  searchable?: boolean;
  canReload?: boolean;
  sort?: Sort;
  hideFooter?: boolean;
  selectAll?: boolean;
  selectOptionColumn?: SelectOptionColumn[];
  tableKey?: string;
  saveTableData?: (data: any) => void;
};

export type Props = {
  options: TableOptions;
  alternative?: boolean;
  selected?: {
    value: any[];
    change: Dispatch<SetStateAction<any>>;
  };
  extraFilters?: { column: string; value: any }[];
  queryParams?: any;
  searchQuery?: string;
  onColumnChanged?: (columns: any) => void;
  onSave?: (data: any) => void;
};

export enum sortBy {
  DESC = 'desc',
  ASC = 'asc'
}

export type useTableReturn = {
  alternative?: boolean;
  buttons?: TableButton[];
  config: Config;
  columns: TableColumn[];
  columnSelects: any;
  defaultFilters: any;
  selectOptionColumn: SelectOptionColumn[];
  limits: number[];
  reloading: boolean;
  search: string;
  setSearch: (value: string) => void;
  menuButtons?: MenuButton[];
  searchable?: boolean;
  selected?: {
    value: any[];
    change: Dispatch<SetStateAction<any>>;
  };
  loaded: boolean;
  isLoading: boolean;
  data: any;
  // response: any;
  tableData: any;
  setTableData: Dispatch<SetStateAction<any>>;
  selectAll: any;
  setNewLimit: (k: number) => () => void;
  totalPages: number;
  handleColumnsSelect: (column: string) => (e: ChangeEvent<HTMLSelectElement>) => void;
  hideFooter?: boolean;
  toggleSelectAll: (e: ChangeEvent<HTMLInputElement>) => void;
  handleFilter: (key: string, value: any) => void;
  handleSelect: (row: any, checked: boolean) => void;
  handleSort: (column: string) => () => void;
  handleRefresh: () => void;
  handleGoPrev: () => void;
  handleGoNext: () => void;
  setSearchableBar: (flag: boolean) => void;
  handleHideColumn: (columnId: string) => void;
  onSave?: (data: any) => void;
};
