import React, { useEffect, useState } from 'react';
import FileViewer from 'react-file-viewer';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { getURLExtension } from 'utils/data';
import useFormHandler from 'hooks/useFormHandler';
import schema from './schema';
import { AlcoholCertificationsStatusTypes } from 'types/alcoholCertification';

const acceptedImgExtensions = ['png', 'jpg', 'jpeg'];

type FileItem = {
  url: string;
  type: string;
};

const AlcoholCertification = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const [files, setFiles] = useState<FileItem[]>([]);
  const formHandler = useFormHandler(schema);
  const employeeId = String(params?.employeeId);

  useEffect(() => {
    api.fetchData(endpoints.GET_CERTIFICATION_URL.get({ employeeId }));
  }, []);

  useEffect(() => {
    if (api.response) {
      const certifications: any[] = api?.response?.alcoholCertification?.files || [];
      const items: FileItem[] = [];

      for (const item of certifications) {
        items.push({ url: item.url, type: getURLExtension(item.url)! });
      }

      setFiles(items);
    }
  }, [api.response]);

  useEffect(() => {
    if (params?.status === null) {
      params.status = AlcoholCertificationsStatusTypes.INCOMPLETE;
    }
    formHandler.setFullForm({ alcoholCertification: params.status });
  }, []);

  return (
    <Base
      header="Alcohol Certification"
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={
        <Button
          text="Save changes"
          onClick={() => onClose({ employeeId, status: formHandler.data.alcoholCertification })}
          disabled={!formHandler.valid}
        />
      }
    >
      <div className="alcohol-viewer-container">
        {files?.map((file, index) =>
          file.type === 'pdf' ? (
            <div key={index} className="viewer-pdf">
              <FileViewer fileType="pdf" filePath={file.url} />
            </div>
          ) : acceptedImgExtensions.includes(file.type) ? (
            <div key={index} className="viewer-img">
              <img src={file.url} className="image" />
            </div>
          ) : null
        )}
        <div className="form-wrapper">
          <DynamicForm handler={formHandler} />
        </div>
      </div>
    </Base>
  );
};

export default AlcoholCertification;
