import React, { useEffect, useState } from 'react';
import { EmojiSad } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setToken } from 'store/actions/user';
import { useToast } from 'hooks/useToast';
import { RootState } from 'types/store';

export enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export type Config = {
  endpoint: string;
  method?: Methods;
};

const API_URL = process.env.REACT_APP_URL;

function objToQueryString(obj: any) {
  const keyValuePairs = [];

  for (const key in obj) {
    keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
  }

  return keyValuePairs.join('&');
}

export default function useApi() {
  const { token, refreshToken } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const notify = useToast();
  const [response, setResponse] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  async function fetchData(config: Config, data?: any, successMessage: boolean = false) {
    const headers: Headers = new Headers();
    const method = config.method || Methods.GET;
    let url = `${API_URL}${config.endpoint}`;

    setIsLoading(true);

    headers.append('Content-Type', 'application/json');
    !!token && headers.append('Authorization', `Bearer ${token}`);
    !!refreshToken && headers.append('refresh-token', refreshToken);

    if (data && method === Methods.GET) {
      url += `?${objToQueryString(data)}`;
    }

    const r = await fetch(url, {
      method,
      headers,
      body: data && method !== Methods.GET ? JSON.stringify(data) : undefined
    });

    if (!r.ok) {
      try {
        const errorMessage = (await r.json()).message;
        notify(errorMessage, <EmojiSad />);
        console.log('Fetch error:', errorMessage);

        if (r.status === 401) {
          dispatch(logout());
        }
      } catch (e) {
        notify(r.statusText, <EmojiSad />);
      }

      setIsLoading(false);
      setLoaded(true);
    } else {
      const response = await r.json();
      setResponse(response);
      successMessage && notify(response.message);

      setIsLoading(false);
      setLoaded(true);

      return response;
    }
  }

  const uploadFileToS3 = async (file: Blob, fileType: string, url: string) => {
    setIsLoading(true);

    const contentTypes: { [key: string]: string } = {
      pdf: 'application/pdf',
      jpeg: 'image/jpeg',
      png: 'image/png'
    };

    const headers = new Headers();
    headers.append('Content-Type', contentTypes[fileType]);

    try {
      const response = await fetch(url, {
        method: Methods.PUT,
        headers,
        body: file
      });

      if (!response.ok) {
        console.error('Fetch error');
      }
    } catch (error) {
      console.error('Fetch error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (response && response.token) {
      dispatch(setToken(response.token));
    }
  }, [response]);

  return { response, isLoading, fetchData, uploadFileToS3, loaded };
}
