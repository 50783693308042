import React, { useEffect } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import endpoints from 'constants/endpoints';
import { changeTimezoneAndConvertToUTC } from 'helpers/date';
import useApi from 'hooks/useApi';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionSalesStartedAtModal = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const formHandler = useFormHandler(schema);

  const handleSubmit = async () => {
    formHandler.setIsSubmitting(true);
    const dateInUTC = changeTimezoneAndConvertToUTC(formHandler.data.startedAt, params?.timezone);

    const r = await api.fetchData(
      endpoints.EDIT_EVENT_START_SALES.get({ id: params.id }),
      {
        eventId: params.id.toString(),
        salesTime: {
          startTime: dateInUTC.toISO()
        }
      },
      true
    );

    if (r?.success) {
      formHandler.setIsSubmitting(false);
      onClose(r.data.sales_starts_at);
    } else {
      formHandler.setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (params?.startedAt) {
      formHandler.setFullForm({ startedAt: utcToZonedTime(params.startedAt, params?.timezone) });
    }
  }, []);

  return (
    <Base
      header="Edit sales started at"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" loading={api.isLoading} disabled={!formHandler.valid} onClick={handleSubmit} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionSalesStartedAtModal;
