/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import joi from 'joi';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import useFormHandler from 'hooks/useFormHandler';
import Base from '../../base';
import schema, { triggers } from './schema';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { tr } from 'date-fns/locale';

const ResentNotificationModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  const handleSubmit = () => {
    const { data, schema } = formHandler;
    const formData = { ...data };

    if (schema.account.hide) {
      delete formData.account;
    }
    if (schema.venue.hide) {
      delete formData.venue;
    }

    onClose(formData);
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_NOTIFICATION.get({ id: String(params.id) }));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      const notification = api.response.data;
      if (!notification.account_id && !notification.venue_id) {
        formHandler.setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              hide: true,
              joi: joi.allow('').optional()
            },
            venue: {
              ...schema.venue,
              hide: true,
              joi: joi.allow('').optional()
            }
          },
          {
            title: notification.title,
            message: notification.message,
            trigger: triggers.ALL_EMPLOYEES
          }
        );
      }

      if (notification.account_id) {
        formHandler.setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              defaultLabel: notification.account_name,
              hide: false
            },
            venue: {
              ...schema.venue,
              hide: true,
              joi: joi.allow('').optional()
            }
          },
          {
            title: notification.title,
            message: notification.message,
            trigger: triggers.ONLY_EMPLOYEES_FROM_ACCOUNT,
            account: String(notification.account_id)
          }
        );
      }
      if (notification.venue_id) {
        formHandler.setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              hide: true,
              joi: joi.allow('').optional()
            },
            venue: {
              ...schema.venue,
              defaultLabel: notification.venue_name,
              hide: false
            }
          },
          {
            title: notification.title,
            message: notification.message,
            trigger: triggers.ONLY_EMPLOYEES_FROM_VENUE,
            venue: String(notification.venue_id)
          }
        );
      }
    }
  }, [api.loaded]);

  useEffect(() => {
    const { data, setFullSchema } = formHandler;

    switch (data.trigger) {
      case triggers.ALL_EMPLOYEES:
        setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              hide: true,
              joi: joi.allow('').optional()
            },
            venue: {
              ...schema.venue,
              hide: true,
              joi: joi.allow('').optional()
            }
          },
          {
            ...data,
            account: '',
            venue: ''
          }
        );
        break;
      case triggers.ONLY_EMPLOYEES_FROM_ACCOUNT:
        setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              hide: false
            },
            venue: {
              ...schema.venue,
              hide: true,
              joi: joi.allow('').optional()
            }
          },
          {
            ...data,
            venue: ''
          }
        );
        break;
      case triggers.ONLY_EMPLOYEES_FROM_VENUE:
        setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              hide: true,
              joi: joi.allow('').optional()
            },
            venue: {
              ...schema.venue,
              hide: false
            }
          },
          {
            ...data,
            account: ''
          }
        );
        break;
      default:
        break;
    }
  }, [formHandler.data.trigger]);

  return (
    <Base
      header="Create Notification"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default ResentNotificationModal;
