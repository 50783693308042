import React from 'react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { extendedDateTimeFormat } from 'constants/dates';
import { localTimezone } from 'constants/timeZones';
import { utcDate } from 'helpers/date';

type Props = {
  value: string;
  timezone?: string;
};

const DateTimeFormat = ({ value, timezone }: Props) => {
  if (!value) {
    return <span>no date</span>;
  }

  if (timezone === localTimezone) {
    return <span>{format(new Date(value), extendedDateTimeFormat)}</span>;
  }

  if (timezone) {
    const zonedDate = utcToZonedTime(value, timezone);
    return <span>{format(zonedDate, extendedDateTimeFormat)}</span>;
  }

  return <span>{format(utcDate(value), extendedDateTimeFormat)}</span>;
};

export default DateTimeFormat;
