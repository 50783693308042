import React, { useEffect } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import { changeTimezoneAndConvertToUTC } from 'helpers/date';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const CheckInOutModal = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const formHandler = useFormHandler(schema);

  const handleSubmit = async () => {
    const { checkIn, checkOut } = formHandler.data;

    const data = {
      checkIn: checkIn ? changeTimezoneAndConvertToUTC(checkIn, params.timezone) : null,
      checkOut: checkOut ? changeTimezoneAndConvertToUTC(checkOut, params.timezone) : null
    };

    formHandler.setIsSubmitting(true);

    const config = endpoints.EDIT_CHECK_IN_OUT.get({ id: params.id });
    const response = await api.fetchData(config, data, true);

    if (response?.success) {
      onClose(true);
    } else {
      formHandler.setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const checkIn = params.checkIn ? utcToZonedTime(params.checkIn, params.timezone) : null;
    const checkOut = params.checkOut ? utcToZonedTime(params.checkOut, params.timezone) : null;

    formHandler.setFullForm({ checkIn, checkOut });
  }, []);

  return (
    <Base
      header="Edit check in/out time"
      scrollable={false}
      closing={closing}
      onClose={onClose}
      size="sm"
      footer={
        <Button
          text="Done"
          loading={api.isLoading}
          disabled={!formHandler.valid || formHandler.isSubmitting}
          onClick={handleSubmit}
        />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default CheckInOutModal;
