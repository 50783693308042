import React, { forwardRef } from 'react';
import { flexRender, Table as ITable } from '@tanstack/react-table';
import classNames from 'classnames';

type AlternativeTableProps<T> = {
  config: ITable<T>;
  layout?: 'fixed' | 'auto';
  fetchMoreOnBottomReached?: (containerRefElement?: HTMLDivElement | null) => void;
  isLoading?: boolean;
  className?: string;
};

const AlternativeTable = forwardRef<HTMLDivElement, AlternativeTableProps<any>>((props, ref) => {
  const { config, isLoading, layout = 'fixed', fetchMoreOnBottomReached, className } = props;

  return (
    <div
      className={classNames('alternative-table', className)}
      ref={ref}
      onScroll={e => fetchMoreOnBottomReached && fetchMoreOnBottomReached(e.target as HTMLDivElement)}
    >
      <table
        className={classNames({
          'layout-fixed': layout === 'fixed',
          'layout-auto': layout === 'auto'
        })}
      >
        <thead>
          {config.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className="body strong">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {config.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td className="body" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <div className="loading-indicator body">Loading...</div>}
      {!isLoading && config.getRowModel().rows.length === 0 && (
        <div className="loading-indicator body">No data found</div>
      )}
    </div>
  );
});

export default AlternativeTable;
